import {Formik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {Alert, Button, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {toast} from 'react-toastify'
import useAppToast from 'src/hooks/useAppToast'
import {PlatformDataType} from 'src/interfaces/Platform.interface'
import {
  MORE_MAPPING_FORM_DATA,
  MORE_TEMPLATE_DATA,
  MORE_TEMPLATE_DATA_ON_GET,
  MoreFieldMappingItem,
  MoreMappingInputAttribute,
  MoreMapping_Item,
  MoreMapping_Item_On_Get,
  PRICE_TEMPLATE_ITEM,
} from 'src/interfaces/Template.interface'
import {
  savePlatformPriceAsync,
  updatePlatformPriceAsync,
} from 'src/redux/actions/productPricingAction'
import {useAppDispatch} from 'src/redux/storeHooks'
import InputAttributes from './InputAttributes'
import InputAttributes2 from './InputAttributes2'
import {
  createMoreMappingAsync,
  updateMoreMappingAsync,
} from 'src/redux/actions/templateAndPricingActions'
type AddNewMoreMappingTemplateModalPropsType = {
  isVisible: boolean
  item: MoreFieldMappingItem
  closeModal: () => void
  selectedSecondaryPlatform: PlatformDataType | null
  moreMappingData: MoreMapping_Item_On_Get | null
  //   updateItem: PRICE_TEMPLATE_ITEM|null
  getLatestMoreMappingDataList: () => void
}

const renderTooltip = (props) => (
  <Tooltip id='button-tooltip' {...props}>
    Use this Switch when you want all Products to use this Mapping/Rule.
  </Tooltip>
)

const AddNewMoreMappingTemplateModal: FC<AddNewMoreMappingTemplateModalPropsType> = ({
  isVisible,
  item,
  closeModal,
  selectedSecondaryPlatform,
  moreMappingData = null,
  getLatestMoreMappingDataList,
  // updateItem,
  // getLatestPriceTemplateList,
}) => {
// if mapping option is DEFAULT and input_type TEXT hai then value_str
// if mapping option is DEFAULT and input_type SELECT hai then value
// if mapping option is NATIVE/CUSTOM and input_type SELECT hai then mapped_field


  const [finalPayload, setFinalPayload] = useState<any>()
  const dispatch = useAppDispatch()
  const {successToast, errorToast, warningToast} = useAppToast()

  // type INITIAL_VALUES = {
  //     name:string
  //     platform_id:number

  //  isDefault:boolean
  //   }

  const initialValues: MORE_MAPPING_FORM_DATA = {
    name: moreMappingData?.name ? moreMappingData?.name : '', // Empty string for name
    platform_id: moreMappingData?.platform?.id ? moreMappingData?.platform?.id : null, // Platform ID from selectedSecondaryPlatform (assuming it's a number)
    platform_field: moreMappingData?.platform_field?.id
      ? moreMappingData?.platform_field?.id
      : null, // Set platform_field to a default value (can be adjusted based on your needs)
    mapped_field: moreMappingData?.mapped_field ? moreMappingData.mapped_field?.id : null , // Set mapped_field to null initially
    value: moreMappingData?.value ? moreMappingData?.value?.id : null, // Set value to null initially
    value_str: moreMappingData?.value_str ? moreMappingData?.value_str : '', // Set value_str to null initially
    isDefault: moreMappingData?.isDefault ? moreMappingData?.isDefault : false, // Set isDefault to false initially
    template_data: moreMappingData?.template_data?.length
      ? moreMappingData.template_data.map((res: MORE_TEMPLATE_DATA_ON_GET) => {
          return {
            id: res.id,
            mapped_field: res.mapped_field ? res.mapped_field.id : null,
            value: res.value ? res.value.id : null,
            value_str: res.value_str,
          }
        })
      :  [ {
        id: null,
        mapped_field: null,
        value: null,
        value_str: '',
      }],
  }

  console.log('item to be mapped', item)
  const handleAttributeSelection = (selectedAttributes) => {
    if (!selectedAttributes) {
      console.error('Attributes data not available')
      return
    }

    // Get the value entered in the input field for category name

    const payload = {
      platform_id: selectedSecondaryPlatform?.id,
      name: 'categoryName', // Set the category name from the input field
      category_data: '', // Assuming last selected category
      attributes: selectedAttributes,
    }

  
    // Further actions with payload (e.g., send to API)
    setFinalPayload(payload)
  }

  return (
    <>
      <Modal size={'lg'} backdrop='static' centered show={isVisible} onHide={closeModal}>
        <Formik
          // initialValues={initialValues}
          // onSubmit={(values,helpers)=>{
          //   const {name,platform_id,isDefault} = values
          // }}
          initialValues={initialValues}
          onSubmit={(values, helpers) => {
            console.log('values', helpers)
            helpers.setSubmitting(true)
            if (moreMappingData) {
              //that means call update
              const payload = {
                name: values?.name, // Empty string for name
                platform_id: selectedSecondaryPlatform?.id!, // Platform ID from selectedSecondaryPlatform (assuming it's a number)
                platform_field: item?.id, // Set platform_field to a default value (can be adjusted based on your needs)
                mapped_field: values?.mapped_field, // Set mapped_field to null initially
                value: values?.value, // Set value to null initially
                value_str: values?.value_str, // Set value_str to null initially
                isDefault: values?.isDefault, // Set isDefault to false initially
                template_data: item.input_type.includes('MULTI') ? values?.template_data.map((item) => {
                  {
                    if(item?.id||item?.mapped_field||item?.value||item?.value_str ){
                      return {
                        id: item.id,
                        mapped_field: item.mapped_field,
                        value: item.value,
                        value_str: item.value_str,
                      }
                    }
                  }
                }) : [] ,
              }

              console.log('update  payload', values.template_data)
              dispatch(
                updateMoreMappingAsync({
                  platforn_field_template_id: moreMappingData.id + '',
                  payload: payload,
                })
              )
                .then((response) => {
                  console.log('response platform----', response)
                  if (response.payload?.data?.error) {
                    warningToast(response.payload?.data?.error)
                    helpers.setSubmitting(false)
                  }
                  if (response.payload?.id) {
                    successToast('Mappping updated successfully')
                    helpers.setSubmitting(false)
                    getLatestMoreMappingDataList()
                    closeModal()
                    
                  }
                  helpers.setSubmitting(false)
                })
                .catch((error) => {
                  console.log(' platform error', error)
                  helpers.setSubmitting(false)
                })
            } else {
              // call create
              const payload = {
                name: values?.name, // Empty string for name
                platform_id: selectedSecondaryPlatform?.id!, // Platform ID from selectedSecondaryPlatform (assuming it's a number)
                platform_field: item?.id, // Set platform_field to a default value (can be adjusted based on your needs)
                mapped_field: values?.mapped_field, // Set mapped_field to null initially
                value: values?.value, // Set value to null initially
                value_str: values?.value_str, // Set value_str to null initially
                isDefault: values?.isDefault, // Set isDefault to false initially
                template_data: values?.template_data.map((item) => {
                  return {
                    mapped_field: item.mapped_field,
                    value: item.value,
                    value_str: item.value_str,
                  }
                })
              }

              console.log('payload', payload)
              dispatch(createMoreMappingAsync(payload))
                .then((response) => {
                  console.log('response platform----', response)
                  if (response.payload?.data?.error) {
                    warningToast(response.payload?.data?.error)
                    helpers.setSubmitting(false)
                  }
                  if (response.payload?.id) {
                    successToast('Mapping saved successfully')
                    helpers.setSubmitting(false)
                    getLatestMoreMappingDataList()
                    closeModal()
                    
                  }
                  helpers.setSubmitting(false)
                })
                .catch((error) => {
                  console.log(' platform error', error)
                  helpers.setSubmitting(false)
                })
            }
          }}
        >
          {({values, handleChange, handleSubmit, setFormikState, isSubmitting}) => {
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <Modal.Header>
                    {/* <Modal.Title>Add New Mapping Rule </Modal.Title> */}
                    {moreMappingData?.id ? (
                      <Modal.Title>Update Mapping Rule </Modal.Title>
                    ) : (
                      <Modal.Title>Add New Mapping Rule </Modal.Title>
                    )}

                    <div className='d-inline-flex'>
                      <Button
                        size='sm'
                        className='mx-2'
                        type='button'
                        disabled={isSubmitting}
                        onClick={() => {
                          closeModal()
                        }}
                      >
                        Close
                      </Button>
                      <Button size='sm' variant='success' disabled={isSubmitting} type='submit'>
                        { isSubmitting ? 'Saving...' : 'Save Changes'}
                      </Button>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div className=' d-flex justify-content-end form-check form-switch  mt-2'>
                      <div>
                        <label className='form-label mt-1'>
                          Default&nbsp;
                          <OverlayTrigger placement='left' overlay={renderTooltip}>
                            <i className='bi bi-info-circle'></i>
                          </OverlayTrigger>
                        </label>
                      </div>
                      <input
                        className='ms-4 mb-4 form-check-input h-20px w-40px'
                        type='checkbox'
                        name='isDefault'
                        checked={values.isDefault}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='mb-8  '>
                     
                        <label className='form-label'>Rule name</label>
                    
                     
                        <input
                          type='text'
                          autoComplete='OFF'
                          className='form-control'
                          placeholder={'Enter name'}
                          name='name'
                          value={values.name}
                          onChange={handleChange}
                        />
                      
                    </div>

                    {/* give options to map attribute  */}
                    <div>
                      <InputAttributes2
                        //   key={categoryData + ''}
                        paltformId={selectedSecondaryPlatform?.id}
                        categoryAttributes={item}
                        // handleAttributeSelection={handleAttributeSelection} //unused function commented
                        handleChange={handleChange}
                        values={values}
                        setFormikState={setFormikState}
                        moreMappingData={moreMappingData}
                        getLatestMoreMappingDataList={getLatestMoreMappingDataList}
                        //   updatingMapData={attributesInUpdateCase}
                      />
                    </div>
                  </Modal.Body>
                </form>
              </>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}

export default AddNewMoreMappingTemplateModal
