import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'
import useAppToast from 'src/hooks/useAppToast'
import AppDatePickerRange from '../orders/components/AppDatePickerRange'
import {Spinner} from 'react-bootstrap'
import StatusButton from '../orders/StatusButton'
import StatusButtonAdmin from './StatusButtonAdmin'
import {KTIcon, KTSVG} from 'src/marketConnect/helpers'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {
  deleteUserAsync,
  getCustomersAsync,
  loginAsCustomerAsync,
  updateCustomerStatusAsync,
} from 'src/redux/actions/customerAction'
import {UserActionsCell} from './UserActionCell'
import {CUSTOMER} from 'src/app/_interfaces/customer.interface'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'
import {
  accessSessionTokenKeyName,
  loggedInAdminAccessToken,
  loggedInAdminRefreshToken,
  refreshSessionTokenKeyName,
} from 'src/utils/constants'
import {useNavigate} from 'react-router-dom'
import {setLoggingData} from 'src/redux/slices/authSlice'
import {fetchUserAccountDetailsAsync} from 'src/redux/actions/accountActions'
import {messageTemplete} from 'src/utils/messageTemplete'
import EnableProductModule from './EnableProductModule'
const CommonColumn: FC<{rowData: string}> = ({rowData}) => {
  return (
    <div className='row'>
      <p className='d-inline-block  ' style={{maxWidth: '180px'}}>
        {rowData}
      </p>
    </div>
  )
}

const ManageCustomers: FC = () => {
  const [searchString, setSearchString] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')

  const {successToast, errorToast, warningToast} = useAppToast()

  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const [showDateRangePickerForOrders, setShowDateRangePickerForOrders] = useState(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {customerList, customerLoader, totalCustomer} = useAppSelector((state) => state.customer)

  //for delete user
  const [confirmDeleteUser, setConfirmDeleteUser] = useState(false)
  const [deleting, setDeleting] = useState(false)

  //used for update status
  const [confirmUpdateStatus, setConfirmUpdateStatus] = useState(false)
  const [updatingStatus, setUpdatingStatus] = useState(false)
  // -------

  //used for login as customer
  const [confirmLoginAsUser, setConfirmLoginAsUser] = useState(false)
  const [loggingIn, setLoggingIn] = useState(false)
  //------
  const [selectedUserData, setSelectedUserData] = useState<null | CUSTOMER>(null)
  const [paginationData, setPaginationData] = useState({
    page_limit: 10,
    page_num: 1,
    order_by: '-id',
    search: '',
    user_type: 'USER',
    from_date: '',
    to_date: '',
    sync_from_date: '',
    sync_to_date: '',
    source: '',
  })

  const updateStatusAsync = (data: CUSTOMER) => {
    setUpdatingStatus(true)
    dispatch(
      updateCustomerStatusAsync({
        user_id: data.id,
        status: data.status === true ? 'inactive' : 'active',
      })
    )
      .then((res) => {
        setUpdatingStatus(false)
        successToast(res.payload?.massage)
        setConfirmUpdateStatus(false)
        setSelectedUserData(null)
        dispatch(getCustomersAsync({...paginationData}))
      })
      .catch(() => {
        setUpdatingStatus(false)
        dispatch(getCustomersAsync({...paginationData}))
      })
  }

  const loginCustomerAsync = (data: CUSTOMER) => {
    setLoggingIn(true)
    dispatch(loginAsCustomerAsync({user_id: data.id}))
      .then((res) => {
        console.log('login resonse', res)
        if (res.payload?.status_code == '200') {
          let currentaccesstokenofadmin = localStorage.getItem(accessSessionTokenKeyName)
          let currentrefreshtokenofadmin = localStorage.getItem(refreshSessionTokenKeyName)
          if (currentaccesstokenofadmin && currentrefreshtokenofadmin) {
            localStorage.setItem(accessSessionTokenKeyName, res.payload?.data?.access)
            localStorage.setItem(refreshSessionTokenKeyName, res.payload?.data?.refresh)
            localStorage.setItem(loggedInAdminAccessToken, currentaccesstokenofadmin)
            localStorage.setItem(loggedInAdminRefreshToken, currentrefreshtokenofadmin)
            dispatch(fetchUserAccountDetailsAsync()).then((res2) => {
              navigate('/auth')
            })
          } else {
            warningToast('Unauthorize access')
          }
        }
        setLoggingIn(false)
        successToast(messageTemplete.LOGIN_SUCCESS)
        setConfirmLoginAsUser(false)
        setSelectedUserData(null)
        // dispatch(getCustomersAsync({...paginationData}))
      })
      .catch((error) => {
        setConfirmLoginAsUser(false)
        dispatch(getCustomersAsync({...paginationData}))
      })
  }

  const columns: TableColumn<CUSTOMER>[] = [
    {
      name: 'Customer',
      cell: (row) => (row ? <CommonColumn rowData={row.name ?? ''} /> : null),
      minWidth: '140px',
    },

    {
      name: 'Email',
      selector: (row) => row.email,
      cell: (row) => (row ? <CommonColumn rowData={row.email} /> : null),
      minWidth: '200px',
    },

    {
      name: 'Source',
      selector: (row) => row.source,
      cell: (row) => (row ? <CommonColumn rowData={row?.source ?? 'COMMERCIUM'} /> : null),
      minWidth: '160px',
    },

    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => {
        if (row.status) {
          return <span className=''>Active</span>
        } else {
          return <span className=''>Inactive</span>
        }
      },
      center: true,
      minWidth: '120px',
    },

    {
      name: 'Current Plan',
      cell: (row) => (
        <div>
          <small className=''>{row?.user_plan_name}</small>
          <br />
          <small>
            {row?.user_plan_price && row?.user_plan_price_currency
              ? row?.user_plan_price + ' ' + row?.user_plan_price_currency
              : '0'}
            / <span style={{fontSize: '9px'}}>{' Monthly'}</span>
          </small>
        </div>
      ),
      minWidth: '140px',
    },
    {
      name: 'Sync Count',
      cell: (row) => (
        <div>
          <p>
            Orders : &nbsp;&nbsp;&nbsp;<span className='fw-bold'>{row?.synced_order_count}</span>
          </p>
          <p>
            Products : <span className='fw-bold'>{row?.synced_product_count}</span>
          </p>
        </div>
      ),
      center: true,
      minWidth: '180px',
    },

    {
      name: 'Joined On (UTC)',

      cell: (row) => (row.sign_up ? moment.utc(row?.sign_up).format('hh:mm A, DD-MMM-YYYY') : null),
      sortable: false,
      center: true,
      minWidth: '170px',
    },
    {
      name: 'Last Login (UTC)',

      cell: (row) =>
        row?.last_login ? moment.utc(row?.last_login).format('hh:mm A, DD-MM-YYYY') : null,
      minWidth: '160px',
    },
    {
      name: 'Enable Product Module',
      cell: (row) => (
        <div>
          <EnableProductModule isEnableProductModule={row.isEnableProductModule} customer={row} />
        </div>
      ),

      minWidth: '200px',
    },
    {
      name: 'Action',
      cell: (row) => (
        <UserActionsCell
          customerDetail={row}
          onDeleteCustomer={()=>{
            setSelectedUserData(row)
            setConfirmDeleteUser(true)
          }}
          onLoginAsCustomer={() => {
            setConfirmLoginAsUser(true)
            setSelectedUserData(row)
          }}
          updateCustomerStatus={() => {
            setConfirmUpdateStatus(true)
            setSelectedUserData(row)
          }}
        />
      ),
      center: true,
      minWidth: '140px',
    },
  ]

  const handleResetFilters = () => {
    setPaginationData({
      page_limit: 10,
      page_num: 1,
      order_by: 'id',
      search: '',
      user_type: 'USER',
      from_date: '',
      to_date: '',
      sync_from_date: '',
      sync_to_date: '',
      source: '',
    })
    setSearchString('')
  }

  useEffect(() => {
    dispatch(getCustomersAsync({...paginationData}))
  }, [paginationData])

  return (
    <div>
      <>
        <div className='mb-3'>
          <span className='fw-bold text-lg ' style={{fontSize: '16px'}}>
            Manage Customers
          </span>
        </div>

        <div className='card border-0 shadow rounded '>
          <div className='card-header bg-white'>
            <div className='card-title'>
              <input
                className='form-control input-sm '
                type='search'
                placeholder='Search By Name or Email'
                aria-label='Search'
                onChange={(ev) => {
                  setSearchString(ev.target.value)
                }}
                onKeyDown={(ev) => {
                  if (ev.key == 'Enter') {
                    setPaginationData((pre) => {
                      return {...pre, search: searchString, page: 1}
                    })
                  }
                }}
                value={searchString}
                style={{minWidth: '180px'}}
              />
            </div>
            <div className='card-toolbar'>
              <div className='d-flex justify-content-end mb-4 mt-3 me-3'>
                <div className=''>
                  <div className='d-flex align-items-center'>
                    <select
                      className='form-select py-2 me-2 min-w-150px'
                      value={paginationData.source}
                      onChange={(ev) =>
                        setPaginationData((pre) => ({...pre, source: ev.target.value}))
                      }
                    >
                      <option value=''>All</option>
                      <option value='WIX'>WIX</option>
                      <option value='COMMERCIUM'>COMMERCIUM</option>
                      <option value='WOOCOMMERCE'>WOOCOMMERCE</option>
                      <option value='SHOPIFY'>SHOPIFY</option>
                      <option value='ECWID'>ECWID</option>
                      <option value='LOYVERSE'>LOYVERSE</option>
                    </select>

                    <button
                      className='form-control rounded input me-2'
                      style={{height: '100%', padding: '0.5rem 1rem'}}
                      onClick={() => setShowDateRangePickerForOrders(!showDateRangePickerForOrders)}
                    >
                      {paginationData.sync_from_date && paginationData.sync_to_date
                        ? `${moment(paginationData.sync_from_date).format(
                            'YYYY/MM/DD'
                          )}  -  ${moment(paginationData.sync_to_date).format('YYYY/MM/DD')}`
                        : 'Filter Orders'}
                    </button>
                  </div>

                  <AppDatePickerRange
                    isVisible={showDateRangePickerForOrders}
                    handleClose={() => setShowDateRangePickerForOrders(false)}
                    onPickRange={({startDate, endDate}) => {
                      setPaginationData((pre) => ({
                        ...pre,
                        sync_from_date: moment(startDate).format('YYYY-MM-DD'),
                        sync_to_date: moment(endDate).format('YYYY-MM-DD'),
                        page_num: 1,
                      }))
                    }}
                  />
                </div>

                <div className=''>
                  <div className='d-flex align-items-center'>
                    <button
                      className='form-control rounded input me-2'
                      style={{height: '100%', padding: '0.5rem 1rem'}}
                      onClick={() => setShowDateRangePicker(!showDateRangePicker)}
                    >
                      {paginationData.from_date && paginationData.to_date
                        ? `${moment(paginationData.from_date).format('YYYY/MM/DD')}  -  ${moment(
                            paginationData.to_date
                          ).format('YYYY/MM/DD')}`
                        : 'Filter Customer'}
                    </button>
                  </div>

                  <AppDatePickerRange
                    isVisible={showDateRangePicker}
                    handleClose={() => setShowDateRangePicker(false)}
                    onPickRange={({startDate, endDate}) => {
                      setPaginationData((pre) => ({
                        ...pre,
                        from_date: moment(startDate).format('YYYY-MM-DD'),
                        to_date: moment(endDate).format('YYYY-MM-DD'),
                        page_num: 1,
                      }))
                    }}
                  />
                </div>

                <div className='mx-2'>
                  <button
                    type='button'
                    className='btn fs-8 btn-sm btn-primary fw-bold'
                    onClick={handleResetFilters}
                  >
                    Reset Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            fixedHeader={true}
            data={customerList}
            columns={columns}
            //   onSort={handleSort}
            paginationTotalRows={totalCustomer}
            responsive
            pagination
            paginationServer
            progressPending={customerLoader}
            progressComponent={<Spinner animation='border' />}
            onChangePage={(pageNumber, totalRows) => {
              setPaginationData((pre) => {
                return {...pre, page_num: pageNumber}
              })
            }}
            onChangeRowsPerPage={(currentPage) => {
              setPaginationData((pre) => {
                return {...pre, limit: currentPage}
              })
            }}
            customStyles={{
              table: {
                style: {
                  height: '52vh',
                },
              },
              headCells: {
                style: {fontWeight: 'bold', fontSize: '14px'},
              },
              cells: {
                style: {},
              },
            }}
          />
          <CommonAlertModal
            key={'status change modal'}
            title={` You are ${
              selectedUserData?.status === true ? 'Deactivating' : 'Activating'
            } the ${
              selectedUserData?.name ? selectedUserData.name : 'customer'
            }. Do you want to continue?`}
            subtitle='Press "Confirm" for continue'
            successBtnTitle={updatingStatus ? 'Updating...' : 'Confirm'}
            successDisabled={updatingStatus}
            isVisible={confirmUpdateStatus}
            onSuccess={() => {
              if (selectedUserData) {
                updateStatusAsync(selectedUserData)
              }
            }}
            onCancel={() => {
              setConfirmUpdateStatus(false)
              setSelectedUserData(null)
            }}
            isTermsVisible={false}
          />

          <CommonAlertModal
            key={'login modal'}
            title={`You are logging in as ${
              selectedUserData?.name ? selectedUserData.name : 'customer'
            }, do you want to continue?`}
            subtitle='Press "Confirm" for continue'
            successBtnTitle={loggingIn ? 'Logging in...' : 'Confirm'}
            successDisabled={loggingIn}
            isVisible={confirmLoginAsUser}
            onSuccess={() => {
              if (selectedUserData) {
                loginCustomerAsync(selectedUserData)
              }
            }}
            onCancel={() => {
              setConfirmLoginAsUser(false)
              setSelectedUserData(null)
            }}
            isTermsVisible={false}
          />

          <CommonAlertModal
            key={'delete modal'}
            title={`Are you sure? Want to delete ${
              selectedUserData?.name ? selectedUserData.name : 'customer'
            }, do you want to continue?`}
            subtitle='Press "Confirm" for continue'
            successBtnTitle={deleting ? 'Deleting...' : 'Confirm Delete'}
            successDisabled={deleting}
            isVisible={confirmDeleteUser}
            onSuccess={() => {
              if (selectedUserData) {
                //delete functionality
                setDeleting(true)
                dispatch(deleteUserAsync({user_id:selectedUserData.id}))
                .then((res)=>{
                   
                  if(res.payload?.status_code==200){
                    successToast(res.payload?.massage)
                    setConfirmDeleteUser(false)
                    setSelectedUserData(null)
                  }else{
                    warningToast(res.payload?.data?.massage)
                  }
                  dispatch(getCustomersAsync({...paginationData}))
                  setDeleting(false)
                  
                }).catch((err)=>{
                  warningToast('Unable to delete user')
                  setDeleting(false)
                })
              }
            }}
            onCancel={() => {
              setConfirmDeleteUser(false)
              setSelectedUserData(null)
            }}
            isTermsVisible={false}
          />
        </div>
      </>
    </div>
  )
}

export default ManageCustomers
