import React, {FC, useEffect, useState} from 'react'
import axios from 'axios'
import PlatformCard from './components/PlatformCard'
import {isActiveBoolean} from 'src/redux/actions/changeisActiveAction'
import ConnectPlatformModal from './components/ConnectPlatformModal'
import {Platform, PlatformDataType} from 'src/interfaces/Platform.interface'
import {
  connectPlatformAsync,
  fetchConnectedPlatformAsync,
  fetchPlatformsAsync,
  createPlatformSettingAsync,
} from 'src/redux/actions/platformActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {toast} from 'react-toastify'
import {PlatformSettingsDataType} from 'src/interfaces/Platform.interface'
import useAppToast from 'src/hooks/useAppToast'
import { useNavigate } from 'react-router-dom'

const Platforms: FC = () => {
  const {platformData} = useAppSelector((state) => state.platform)
  const {connectedPlatformData} = useAppSelector((state) => state.connectedPlatform)
  const [showConnectPlatformModal, setShowConnectPlatformModal] = useState<boolean>(false)
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(null)
  const [ecommercePlatform, setEcommercePlatform] = useState<any>([])
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<PlatformDataType>()
  const {successToast, warningToast, errorToast} = useAppToast()
    const navigate = useNavigate();

  const {fetchedConnectedPlatformData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const dispatch = useAppDispatch()
  const onClickConnect = (platform: Platform) => {
    console.log('paltform', platform)
    setSelectedPlatform(platform)
    setShowConnectPlatformModal(true)
  }
  const handleCloseModal = () => {
    setShowConnectPlatformModal(false);
    setSelectedPlatform(null); // Reset selected platform
  }
  useEffect(() => {
    dispatch(fetchPlatformsAsync())
    dispatch(fetchConnectedPlatformAsync())
  }, [])
  useEffect(() => {
    if (fetchedConnectedPlatformData.length === 0) {
      let newPlatformData = platformData?.filter((ele) => ['ECOMMERCE','SHIPPING_PLATFORM'].includes(ele.type) )
      setEcommercePlatform(newPlatformData)
    } else {
      setEcommercePlatform(platformData)
    }
  }, [showSettingModal, []])

  const handleDataReceived = (dataCollector: any, friendlyName: string) => {
    console.log('Credentials taken from input:', dataCollector)
    // const email = localStorage.getItem('userid') // Assuming 'userid' is the key you used to store the email
    setShowSettingModal(true)
    if(dataCollector['friendlyName']==''){
      warningToast("Channel name is required")
      return 
    }
    if (selectedPlatform) {
      let isMainStore = fetchedConnectedPlatformData.length === 0
      const payload = {
        name: selectedPlatform.name,
        type: selectedPlatform.type,
        code: selectedPlatform.name,
        display_name: friendlyName,
        platform_info: selectedPlatform.id,
        credentials: {
          identifier: dataCollector.find((item: any) => item.name === 'identifier')?.value || null,
          client_id: dataCollector.find((item: any) => item.name === 'client_id')?.value || null,
          client_secret:
            dataCollector.find((item: any) => item.name === 'client_secret')?.value || null,
          token_id: dataCollector.find((item: any) => item.name === 'token_id')?.value || null,
          token_secret:
            dataCollector.find((item: any) => item.name === 'token_secret')?.value || null,
          refresh_token: null,
          sftp_hostname : dataCollector.find((item: any) => item.name === 'sftp_hostname')?.value || null,
          sftp_username : dataCollector.find((item: any) => item.name === 'sftp_username')?.value || null,
          sftp_password : dataCollector.find((item: any) => item.name === 'sftp_password')?.value || null,
          country : dataCollector.find((item: any) => item.name === 'country')?.value || '',
          proxies : dataCollector.find((item: any) => item.name === 'proxies')?.value || '',
          currency : dataCollector.find((item: any) => item.name === 'currency')?.value || '',


        },
        isConnected: true,
        isActive: true,
        isMainStore: isMainStore,
        isOrderSyncProcessing: false,
        isNewOrderSyncProcessing: false,
        isOrderCreateProcessing: false,
      }
      console.log('payload: ', payload)
      dispatch(connectPlatformAsync(payload))
        .then((response) => {
          dispatch(fetchConnectedPlatformAsync())
          if (response.meta.requestStatus === 'fulfilled') {
            console.log('response after connecting platform ', response)
            const statusCode = response.payload.status_code
            const platformName = payload.name // Assuming name is the property containing the platform name
            // setModalData(response.payload.data)
            if(response.payload?.data?.isMainStore){
              navigate(`/primary-platform-setting/${response.payload?.data?.id}`)
            }else {
              navigate(`/platform-setting/${response.payload?.data?.id}`)
            }
            if (response?.payload?.status_code == 201) {
              successToast(`Platform ${platformName} connected successfully!`)
              setShowSettingModal(false)
              return 
            } else {
              return errorToast(
                `Failed to connect platform ${platformName}. Status code: ${statusCode}`
              )
            }
          } else {
            console.error('Connection failed:', response.payload)
            return toast.error('Failed to connect platform. Please try again.')
          }
        })
        .catch((error) => {
          console.error('Error connecting platform:', error)
          toast.error('An error occurred while connecting the platform. Please try again.')
        })
    }
  }
  const showSuccessToast = () => {
    toast.success('Platform settings created successfully From platform page!', {
      autoClose: 3000, // Set the duration for which the toast will be displayed
    })
    // Set showSettingModal to false after showing the toast
  }
  // const handleSaveSettingsViaPlatform = async (allSettings: PlatformSettingsDataType) => {

  //   try {
  //      await dispatch(createPlatformSettingAsync(allSettings)).then((response)=>{
  //       if(response?.payload?.status_code === 200){
  //           toast.success('Platform settings saved successfully !', {
  //           autoClose: 3000, // Set the duration for which the toast will be displayed
  //         })
  //       }
  //      })
    
  //   } catch (error) {
  //     console.error('Error creating platform settings:', error)
  //   }

  //   // console.log('abhi', connectedPlatformData)
  //   const id = connectedPlatformData?.id
  //   const display_name = connectedPlatformData?.display_name
  //   const isActive = connectedPlatformData?.isActive == false ? true : true
  //   const payload = {
  //     display_name,
  //     isActive,
  //   }
  //   console.log('data', {...payload})
  //   await dispatch(isActiveBoolean({...payload, id}))
  // }

  return (
    <>
      <div>
        <h3>Available E-Commerce Channels</h3>
      </div>
      <div className='py-4'>
        <div className='row g-3 gx-2 g-xl-8 my-2'>
          {ecommercePlatform?.length > 0 &&
            ecommercePlatform.map((item: any) => (
              <React.Fragment key={item.id}>
                <PlatformCard data={item} onClickConnect={() => onClickConnect(item)} />
              </React.Fragment>
            ))}
        </div>
      </div>
     { showConnectPlatformModal ?  
      <ConnectPlatformModal
        isVisible={showConnectPlatformModal}
        closeModal={handleCloseModal}
        data={selectedPlatform}
        onDataReceived={handleDataReceived} // Pass the data receiving function
      /> : null }
      
    </>
  )
}
export default Platforms
