import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import { Spinner } from 'react-bootstrap'
import {Link, useParams} from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import {BillingPlan, UserSubscriptionPlan} from 'src/interfaces/Platform.interface'
import { useLayout} from 'src/marketConnect/layout/core'
import {
  cancelSubscriptionAsync,
  getAllPlansAsync,
  getUserPlanAsync,
  requestFreePlanForWoocom,
  requestSubscriptionAsync,
  updateSubscriptionAsync,
} from 'src/redux/actions/pricingActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'

const Pricing:FC = () => {
  const [billingPeriod, setBillingPeriod] = useState('month')
  const [selectedPlanId, setSelectedPlanId] = useState<string|null>(null)
  const [listOfPlans, setListOfPlans] = useState<any[]>([])
  const [userSubscribedPlan, setUserSubscriptionPlan] = useState<UserSubscriptionPlan>()
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  let {platformId} = useParams()
  platformId  = platformId ? platformId  : `${mainStoreData?.id}`
  const dispatch = useAppDispatch()
  const {successToast, warningToast, errorToast} = useAppToast()
  const [isLoading, setIsLoading] = useState(false)
  const [isCancelSubscriptionConfirm, setIsCancelSubscriptionConfirm] = useState<boolean>(false)
  const [isCanceling, setIsCanceling] = useState<boolean>(false)
  const [isWixDowngrading, setIsWixDowngrading] = useState(false)
  const [isWixCancelling, setIsWixCancelling] = useState(false)
  const [confirmDowngradeToFree, setConfirmDowngradeToFree] = useState(false)
  const [isPlanClicked, setIsPlanClicked] = useState(false)
  const [downgradingPlanId, setDowngradingPlanId] = useState<number|null>(null)
  const [upgradingPlanId, setUpgradingPlanId] = useState<number|null>(null)
  const [trialingPlanId, setTrialingPlanId] = useState<number|null>(null)
  const { fetchedUserAccountData} = useAppSelector(
    (state) => state.userAccountData
  )

  const handleBillingPeriodChange = (period) => {
    setBillingPeriod(period)
     // Reset selected plan when billing period changes
  }

   
  useEffect(() => {
    setIsLoading(true)
    Promise.all([
      dispatch(getAllPlansAsync()).then((response) => {
        if (Array.isArray(response.payload)) {
          setListOfPlans(response.payload)
        } else {
          console.error('Expected an array for listOfPlans:', response.payload)
        }
      }),
      dispatch(getUserPlanAsync()).then((response) => {
        console.log('list of  userplan', response.payload)
        if(response.payload?.data?.billing_plan){
          setUserSubscriptionPlan({...response.payload?.data, user_count:response.payload?.user_count})
        }
        if(response.payload?.status===204){
          //will not get user billing plan
          setUserSubscriptionPlan(undefined)
          // warningToast(response.payload?.data?.error)
        }
        if(response.payload?.status===500){
          warningToast(response.payload?.data?.error)
        }
      })
    ])
    .finally(()=>{
      setIsLoading(false)
    })
  }, [])

  const plans = [
    {
      id: 1,
      price: {
        type: 'PRICE',
        value: 0.0,
        currency_iso: 'INR',
      },
      plan_name: 'Basic',
      plan_id: 'basic_001',
      display_name: 'Starter - Basic plan',
      platform_limit: 1,
      product_limit: 100,
      order_limit: 100,
      isDynamicFieldMappingAllowed: false,
      isDiscogsAutoRelistingAllowed: false,
      isAutoRepricingAllowed: false,
      isBatchUpdatesAllowed: false,
      isTechSupportAllowed: false,
      isDedicatedAccountManagerAllowed:false,
      isLiveChatSupportAllowed:false,
      isEmailSupportAllowed:true,
      isNearRealtimeSyncAllowed:true,
      isUnlimitedChannelIntegrationAllowed:false,
      isInventorySyncAllowed:false,
      isProductListingsAllowed:false,
      description: 'This is a description',
      trialPeriodDays: 7,
      billing_frequency: 'FREE_FOREVER',
      created_at: '2024-04-02T14:28:08Z',
      updated_at: '2024-04-02T14:28:08Z',
      showcase_price: 1,
    },
    {
      id: 2,
      price: {
        type: 'PRICE',
        value: 249.0,
        currency_iso: 'INR',
      },
      plan_name: 'Standard',
      plan_id: 'free',
      display_name: 'Standard plan',
      platform_limit: 10,
      product_limit: 1000,
      order_limit: 1000,
      isDynamicFieldMappingAllowed: false,
      isDiscogsAutoRelistingAllowed: false,
      isAutoRepricingAllowed: true,
      isBatchUpdatesAllowed: true,
      isTechSupportAllowed: true,
      isDedicatedAccountManagerAllowed:true,
      isLiveChatSupportAllowed:true,
      isEmailSupportAllowed:true,
      isNearRealtimeSyncAllowed:false,
      isUnlimitedChannelIntegrationAllowed:true,
      isInventorySyncAllowed:false,
      isProductListingsAllowed:false,
      description: 'This is a description',
      trialPeriodDays: 7,
      billing_frequency: 'EVERY_30_DAYS',
      created_at: '2024-04-02T14:28:08Z',
      updated_at: '2024-04-02T14:28:08Z',
      showcase_price: 2,
    },
    {
      id: 3,
      price: {
        type: 'PRICE',
        value: 450.0,
        currency_iso: 'INR',
      },
      plan_name: 'Premium',
      plan_id: 'premium_001',
      display_name: 'Premium plan',
      platform_limit: 20,
      product_limit: 2000,
      order_limit: 2000,
      isDynamicFieldMappingAllowed: true,
      isDiscogsAutoRelistingAllowed: true,
      isAutoRepricingAllowed: true,
      isBatchUpdatesAllowed: true,
      isTechSupportAllowed: true,
      isDedicatedAccountManagerAllowed:true,
      isLiveChatSupportAllowed:true,
      isEmailSupportAllowed:true,
      isNearRealtimeSyncAllowed:true,
      isUnlimitedChannelIntegrationAllowed:true,
      isInventorySyncAllowed:true,
      isProductListingsAllowed:true,
      description: 'This is a description',
      trialPeriodDays: 7,
      billing_frequency: 'EVERY_30_DAYS',
      created_at: '2024-04-02T14:28:08Z',
      updated_at: '2024-04-02T14:28:08Z',
      showcase_price: 3,
    },
    {
      id: 4,
      price: {
        type: 'PRICE',
        value: 2988.0,
        currency_iso: 'INR',
      },
      plan_name: 'Standard Annual',
      plan_id: 'standard_012',
      display_name: 'Standard plan',
      platform_limit: 30,
      product_limit: 10000,
      order_limit: 10000,
      isDynamicFieldMappingAllowed: false,
      isDiscogsAutoRelistingAllowed: false,
      isAutoRepricingAllowed: true,
      isBatchUpdatesAllowed: true,
      isTechSupportAllowed: true,
      isDedicatedAccountManagerAllowed:true,
      isLiveChatSupportAllowed:false,
      isEmailSupportAllowed:false,
      isNearRealtimeSyncAllowed:false,
      isUnlimitedChannelIntegrationAllowed:false,
      isInventorySyncAllowed:true,
      isProductListingsAllowed:true,
      description: 'This is a description',
      trialPeriodDays: 7,
      billing_frequency: 'ANNUAL',
      created_at: '2024-04-02T14:28:08Z',
      updated_at: '2024-04-02T14:28:08Z',
      showcase_price: 4,
    },
    {
      id: 5,
      price: {
        type: 'PRICE',
        value: 5400.0,
        currency_iso: 'INR',
      },
      plan_name: 'Premium Annual',
      plan_id: 'premium_012',
      display_name: 'Premium plan',
      platform_limit: 50,
      product_limit: 20000,
      order_limit: 20000,
      isDynamicFieldMappingAllowed: true,
      isDiscogsAutoRelistingAllowed: true,
      isAutoRepricingAllowed: true,
      isBatchUpdatesAllowed: true,
      isTechSupportAllowed: true,
      isDedicatedAccountManagerAllowed:true,
      isLiveChatSupportAllowed:true,   
      isEmailSupportAllowed:true,
      isNearRealtimeSyncAllowed:true,
      isUnlimitedChannelIntegrationAllowed:true,
      isInventorySyncAllowed:true,
      isProductListingsAllowed:true,
      description: 'This is a description',
      trialPeriodDays: 7,
      billing_frequency: 'ANNUAL',
      created_at: '2024-04-02T14:28:08Z',
      updated_at: '2024-04-02T14:28:08Z',
      showcase_price: 5,
    },
  ]

  const filteredPlans =
    billingPeriod === 'annual'
      ? listOfPlans.filter(
          (plan) => ((plan.billing_frequency === 'ANNUAL' || plan.billing_frequency === 'FREE_FOREVER') && ![15,27].includes(plan?.id!))
        )
      : listOfPlans.filter(
          (plan) =>
          ( (plan.billing_frequency === 'EVERY_30_DAYS' || plan.billing_frequency === 'FREE_FOREVER') && ![11, 23].includes(plan?.id!))
        )

  // const userSubscribedPlan = {
  //   billing_plan: {
  //     plan_name: 'Basic',
  //     plan_id: 'basic_001',
  //     platform_limit: 1,
  //     product_limit: 100,
  //     display_name: 'Starter - Basic free plan',
  //     description: 'This is a description',
  //     billing_frequency: 'FREE_FOREVER',
  //     price: {
  //       type: 'PRICE',
  //       value: 0.0,
  //       currency_iso: 'INR',
  //     },
  //     showcase_price: {
  //       type: 'PRICE',
  //       value: 0.0,
  //       currency_iso: 'INR',
  //     },
  //     created_at: '2024-04-02T14:28:08Z',
  //     updated_at: '2024-04-02T14:28:08Z',
  //   },
  //   isActive: true,
  //   valid_till: null,
  //   activated_on: null,
  //   subscription_id: '',
  //   user_id: 1,
  // }

  const calculatePrice = (plan:BillingPlan) => {
    if (plan.billing_frequency === 'ANNUAL') {
      const discount = 0.2 // 20% discount
      const discountedPrice = plan.price.value * (1 - discount)
      return discountedPrice // Format to two decimal places
    } else {
      return plan.price.value
    }
  }


  if(isLoading) return <Loader/>

  return (
    <div>
      <div className='d-flex flex-column '>
            <div className='card' id='kt_pricing'>
              <div className='card-body p-4'>
                <div className='d-flex flex-column'>
                  <div className='text-center'>
                    <h1 className='fs-3 mb-2'>Choose Your Plan</h1>
                  </div>
                  <div
                    className='nav-group nav-group-outline mx-auto p-2 mt-3 mb-4 border-0' >
                    <button
                      className={` btn btn-sm  fw-bold  me-4 ${
                        billingPeriod === 'month' ? 'bg-light shadow fs-5 text-primary fw-bold' : 'btn-light'
                      }`}
                      data-kt-plan='month'
                      data-fdprocessedid='ntglqa'
                      onClick={() => handleBillingPeriodChange('month')}
                    >
                      Monthly
                    </button>
                    <button
                      className={` btn btn-sm btn-color-gray-600 btn-active fw-bold d-inline-flex align-items-center ${
                        billingPeriod === 'annual' ? 'bg-light shadow fs-5 text-primary fw-bold' : 'btn-light'
                      }`}
                      data-kt-plan='annual'
                      data-fdprocessedid='g5m52i'
                      onClick={() => handleBillingPeriodChange('annual')}
                    >
                      Annually  <span className='badge badge-warning px-2 ms-2 mb-0 py-2 fs-6' >20% Off</span> 
                    </button>
                  </div>
                  <div className='row g-10 flex-wrap justify-content-center'>
                    {filteredPlans?.length ?
                      filteredPlans?.map((plan) => (
                        <div
                          className='col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 my-2'
                          style={{minHeight: '300px'}}
                          key={plan.id}
                        >
                          <div className='d-flex h-100'>
                            <div
                              // style={planCardStyle(selectedPlanId === plan.plan_id)}
                              className={
                                userSubscribedPlan?.current_billing_plan === plan.id && userSubscribedPlan?.isActive 
                                  ? 'w-100 d-flex flex-column rounded-3 bg-light bg-opacity-105 p-4 border border-2 border-primary shadow-lg'
                                  : 'w-100 d-flex flex-column  shadow rounded-3 bg-light bg-opacity-105 p-4'
                              }
                              // onClick={() => handlePlanSelect(plan.plan_id)} // Handle plan selection on click
                            >
                              <div className='my-1 text-center w-100 '>
                                <h1 className=' fs-4   text-gray-900 fw-bolder p-2 text-truncate'>
                                  {plan.display_name}
                                </h1>
                              </div>
                              <div className='mb-2 text-center'>
                                <div className='text-center'>
                                  <span className=' text-primary fs-2'>
                                    {plan?.price?.value > 0
                                      ? plan?.price?.currency_iso === 'USD'
                                        ? ' $ '
                                        : ' ₹ '
                                      : " "}
                                  </span>
                                  <span className='fw-bold text-primary lh-1' style={{fontSize: '35px'}}>
                                    {plan?.price?.value > 0 ? calculatePrice(plan)?.toFixed(2) : 'Free'}
                                  </span>
                                  <span className='fs-7 fw-semibold opacity-50'>
                                    {plan?.price?.value > 0
                                      ? billingPeriod === 'annual'
                                        ? '/Year'
                                        : '/Month'
                                      : null}
                                  </span>
                                </div>
                              </div>
                              <div className=' my-3'>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                     {plan?.order_limit} Orders/Month
                                  </span>
                                  <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    {plan?.product_limit} SKUs
                                  </span>
                                  <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                  Unlimited Channel Integration
                                  </span>
                                  {plan?.isUnlimitedChannelIntegrationAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                  Inventory Sync
                                  </span>
                                  {plan?.isInventorySyncAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                  Product Listings
                                  </span>
                                  {plan?.isProductListingsAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                  Dedicated Account Manager
                                  </span>
                                  {plan?.isDedicatedAccountManagerAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                  Live Chat Support
                                  </span>
                                  {plan?.isLiveChatSupportAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                    Email Support
                                  </span>
                                  {plan?.isEmailSupportAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-1'>
                                  Near Realtime Sync
                                  </span>
                                  {plan?.isNearRealtimeSyncAllowed ? (
                                    <i className='ki-duotone ki-check-circle fs-3 text-success'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-cross-circle fs-3 text-danger'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </div>
                              </div>

                              <div className='d-flex flex-wrap  justify-content-center  flex-column h-100 align-items-center'>
                                { (userSubscribedPlan?.cancelled_at && userSubscribedPlan.current_billing_plan!==plan?.id || userSubscribedPlan == undefined || userSubscribedPlan.cancelled_at===null  ) ?
                                <>
                                  {
                                    userSubscribedPlan?.current_billing_plan === plan?.id && userSubscribedPlan?.isActive  ? (
                                        <button disabled className='bg-primary border-0 rounded pt-1 text-white fw-bold px-4' 
                                          style={{paddingBottom:'5px'}}
                                        >
                                            Subscribed
                                      </button>
                                    ) : (plan?.price?.value >=
                                        Number(userSubscribedPlan?.billing_plan?.price?.value) || (fetchedUserAccountData?.source=='WOOCOMMERCE' && userSubscribedPlan==undefined ) || (fetchedUserAccountData?.source=='WOOCOMMERCE' && !userSubscribedPlan?.isActive ))   ? (
                                      <button
                                        className='btn btn-sm btn-primary shadow-lg fw-bold fs-6 px-3'
                                        style={{paddingBottom:"8px", paddingTop:'6px' }}
                                        disabled={isPlanClicked}
                                        onClick={() => {
                                          if (
                                            userSubscribedPlan?.subscription_id === '' ||
                                            userSubscribedPlan?.subscription_id === '\n'
                                          ) {
                                            //if user is upgrading first time
                                            if (
                                              userSubscribedPlan?.activated_on == null &&
                                              userSubscribedPlan?.valid_till == null
                                            ) {
                                              setIsPlanClicked(true)
                                              setUpgradingPlanId(plan.id)
                                              dispatch(
                                                requestSubscriptionAsync({
                                                  plan_id: plan.id,
                                                  platform_id: platformId ?? null ,
                                                  // take_free_days_trial: plan.trialPeriodDays,
                                                })
                                              )
                                                .unwrap()
                                                .then((response) => {
                                                  // Check if the response is successful
                                                  if (response.status_code === 201 && response.data) {
                                                    // Automatically open the link in a new tab
                                                    // successToast(response?.massage)
                                                    window.open(response.data, '_self')
                                                    setTimeout(() => {
                                                      setIsPlanClicked(false)
                                                      setUpgradingPlanId(null)
                                                    }, 4000);
                                                  } else {
                                                    // Handle error or different status codes
                                                    console.error('Error response:', response)
                                                    warningToast('Something went wrong. Please try again later')
                                                    setIsPlanClicked(false)
                                                    setUpgradingPlanId(null)
    
                                                    // Optionally, show a user-friendly message or perform other actions
                                                  }
                                                })
                                                .catch((error) => {
                                                  // Handle any errors that occur during dispatch or response handling
                                                  console.error(
                                                    'Error during subscription request:',
                                                    error
                                                  )
                                                    if(process.env['REACT_APP_ENV']==='staging'){
                                                      warningToast('An error occurred ' + error?.data?.massage )
                                                    }else{
                                                      warningToast('An error occurred. Please try again later')
                                                    }
                                                    setIsPlanClicked(false)
                                                    setUpgradingPlanId(null)
                                                  // Optionally, show a user-friendly message or perform other actions
                                                })
                                            } else {
                                              // Dispatch requestSubscriptionAsync if no subscription_id
                                              setIsPlanClicked(true)
                                              setUpgradingPlanId(plan.id)
                                              dispatch(
                                                requestSubscriptionAsync({
                                                  plan_id: plan.id,
                                                  platform_id: platformId ?? null ,
                                                  // take_free_days_trial: plan.trialPeriodDays,
                                                })
                                              )
                                                .unwrap()
                                                .then((response) => {
                                                  // Check if the response is successful
                                                  if (response.status_code === 201 && response.data) {
                                                    // Automatically open the link in a new tab
                                                    // successToast(response?.massage)
                                                    window.open(response.data, '_self')
                                                    setTimeout(() => {
                                                      setIsPlanClicked(false)
                                                      setUpgradingPlanId(null)
                                                    }, 4000);
                                                  } else {
                                                    // Handle error or different status codes
                                                    console.error('Error response:', response)
                                                    warningToast('Something went wrong. Please try again later')
                                                    setIsPlanClicked(false)
                                                    setUpgradingPlanId(null)
                                                    // Optionally, show a user-friendly message or perform other actions
                                                  }
                                                })
                                                .catch((error) => {
                                                  // Handle any errors that occur during dispatch or response handling
                                                  console.error(
                                                    'Error during subscription request:',
                                                    error
                                                  )
                                                  if(process.env['REACT_APP_ENV']==='staging'){
                                                    warningToast('An error occurred ' + error?.data?.massage )
                                                  }else{
                                                    warningToast('An error occurred. Please try again later')
                                                  }
                                                    setIsPlanClicked(false)
                                                    setUpgradingPlanId(null)
                                                  // Optionally, show a user-friendly message or perform other actions
                                                })
                                            }
                                          } else {
                                            // Dispatch updateSubscriptionAsync if subscription_id is present
                                            if(fetchedUserAccountData?.source=='WOOCOMMERCE' && userSubscribedPlan==undefined && plan?.billing_frequency==='FREE_FOREVER' ){
                                              setSelectedPlanId(plan.plan_id)
                                              setConfirmDowngradeToFree(true)
                                            }else{
                                              if((fetchedUserAccountData?.source=='WOOCOMMERCE' && userSubscribedPlan==undefined) || (fetchedUserAccountData?.source=='WOOCOMMERCE' && plan?.billing_frequency!=='FREE_FOREVER'&& !userSubscribedPlan?.subscription_id )  ){
                                                setIsPlanClicked(true)
                                                setUpgradingPlanId(plan.id)
                                                dispatch(
                                                  requestSubscriptionAsync({
                                                    plan_id: plan.id,
                                                    platform_id: platformId ?? null ,
                                                    // take_free_days_trial: plan.trialPeriodDays,
                                                  })
                                                )
                                                  .unwrap()
                                                  .then((response) => {
                                                    // Check if the response is successful
                                                    if (response.status_code === 201 && response.data) {
                                                      // Automatically open the link in a new tab
                                                      // successToast(response?.massage)
                                                      window.open(response.data, '_self')
                                                      setTimeout(() => {
                                                      setIsPlanClicked(false)
                                                      setUpgradingPlanId(null)
                                                      }, 4000);
                                                    } else {
                                                      // Handle error or different status codes
                                                      console.error('Error response:', response)
                                                      // Optionally, show a user-friendly message or perform other actions
                                                      warningToast('Something went wrong. Please try again later')
                                                      setIsPlanClicked(false)
                                                      setUpgradingPlanId(null)
                                                    }
                                                  })
                                                  .catch((error) => {
                                                    // Handle any errors that occur during dispatch or response handling
                                                    console.error(
                                                      'Error during subscription request:',
                                                      error
                                                    )
                                                    if(process.env['REACT_APP_ENV']==='staging'){
                                                      warningToast('An error occurred ' + error?.data?.massage )
                                                    }else{
                                                      warningToast('An error occurred. Please try again later')
                                                    }
                                                    setIsPlanClicked(false)
                                                    setUpgradingPlanId(null)
                                                    // Optionally, show a user-friendly message or perform other actions
                                                  })
    
                                              }else{
                                                setIsPlanClicked(true)
                                                setUpgradingPlanId(plan.id)
                                                dispatch(
                                                  updateSubscriptionAsync({
                                                    plan_id: plan?.id,
                                                    platform_id: Number(platformId) ?? null ,
                                                    subscription_id: userSubscribedPlan?.subscription_id,
                                                  })
                                                )
                                                  .unwrap()
                                                  .then((response) => {
                                                    // Check if the response is successful
                                                    if (response?.status_code === 201 && response?.data) {
                                                      // successToast(response?.massage)
                                                      window.open(response.data, '_self')
                                                      setTimeout(() => {
                                                        setIsPlanClicked(false)
                                                        setUpgradingPlanId(null)
                                                      }, 4000);
                                                    } else {
                                                      // Handle error or different status codes
        
                                                      // errorToast(response?.error)
                                                      console.error('Error response:', response)
                                                      warningToast('Something went wrong. Please try again later')
                                                      setIsPlanClicked(false)
                                                      setUpgradingPlanId(null)
                                                      // Optionally, show a user-friendly message or perform other actions
                                                    }
                                                  })
                                                  .catch((error) => {
                                                    // Handle any errors that occur during dispatch or response handling
                                                    console.error(
                                                      'Error during subscription update:',
                                                      error
                                                    )
                                                    if(process.env['REACT_APP_ENV']==='staging'){
                                                      warningToast('An error occurred ' + error?.data?.massage )
                                                    }else{
                                                      warningToast('An error occurred. Please try again later')
                                                    }
                                                    setIsPlanClicked(false)
                                                    setUpgradingPlanId(null)
                                                    // Optionally, show a user-friendly message or perform other actions
                                                  })
    
                                              }
                                            }
                                          }
                                         
                                        }}
                                      >
                                      {
                                        upgradingPlanId===plan.id && isPlanClicked ? <div className='min-w-100px' ><Spinner animation='border' size='sm' /></div> : (fetchedUserAccountData?.source=='WOOCOMMERCE' && userSubscribedPlan==undefined ) || (fetchedUserAccountData?.source=='WOOCOMMERCE' && !userSubscribedPlan?.isActive ) ? 'Subscribe' : 'Upgrade'
                                      }
                                      </button>
                                    ) : (
                                      <button
                                        className='btn btn-sm btn-primary shadow px-3 mt-2 fw-bold fs-7 pt-1'
                                        style={{paddingBottom:'5px'}}
                                        disabled={isPlanClicked}
                                        onClick={() => {
                                          if(['WIX'].includes(fetchedUserAccountData?.source!)){
                                            setIsWixDowngrading(false);
                                          }else{
                                            if (
                                              userSubscribedPlan?.subscription_id === '' ||
                                              userSubscribedPlan?.subscription_id === '\n'
                                            ) {
                                              // Dispatch requestSubscriptionAsync if no subscription_id
                                              setIsPlanClicked(true)
                                              setDowngradingPlanId(plan.id)
                                              dispatch(
                                                requestSubscriptionAsync({
                                                  plan_id: plan.id,
                                                  platform_id: platformId ?? null ,
                                                  // take_free_days_trial: plan.trialPeriodDays,
                                                })
                                              )
                                                .unwrap()
                                                .then((response) => {
                                                  // Check if the response is successful
                                                  if (response.status_code === 201 && response.data) {
                                                    // Automatically open the link in a new tab
                                                    // successToast(response?.massage)
                                                    window.open(response.data, '_self')
                                                    setTimeout(() => {
                                                      setIsPlanClicked(false)
                                                      setDowngradingPlanId(null)
                                                    }, 4000);
                                                  } else {
                                                    // Handle error or different status codes
                                                    console.error('Error response:', response)
                                                    warningToast('Something went wrong. Please try again later')
                                                    setIsPlanClicked(false)
                                                    setDowngradingPlanId(null)
                                                    // Optionally, show a user-friendly message or perform other actions
                                                  }
                                                })
                                                .catch((error) => {
                                                  // Handle any errors that occur during dispatch or response handling
                                                  console.error(
                                                    'Error during subscription request:',
                                                    error
                                                  )
                                                  if(process.env['REACT_APP_ENV']==='staging'){
                                                    warningToast('An error occurred ' + error?.data?.massage )
                                                  }else{
                                                    warningToast('U An error occurred. Please try again later')
                                                  }
                                                  setIsPlanClicked(false)
                                                  setDowngradingPlanId(null)
                                                  // Optionally, show a user-friendly message or perform other actions
                                                })
                                            } else {
                                              // Dispatch updateSubscriptionAsync if subscription_id is present
                                              if(plan?.billing_frequency==='FREE_FOREVER' ){
                                                setSelectedPlanId(plan.plan_id)
                                                setConfirmDowngradeToFree(true)
                                              }else{
                                                setIsPlanClicked(true)
                                                setDowngradingPlanId(plan.id)
                                                dispatch(
                                                  updateSubscriptionAsync({
                                                    plan_id: plan?.id,
                                                    platform_id: Number(platformId)??null,
                                                    subscription_id: userSubscribedPlan?.subscription_id,
                                                  })
                                                )
                                                  .unwrap()
                                                  .then((response) => {
                                                    // Check if the response is successful
                                                    if (response.status_code === 201 && response.data) {
                                                      // Automatically open the link in a new tab
                                                      window.open(response.data, '_self')
                                                      setTimeout(() => {
                                                        setIsPlanClicked(false)
                                                        setDowngradingPlanId(null)
                                                      }, 4000);
                                                    } else {
                                                      // Handle error or different status codes
                                                      console.error('Error response:', response)
                                                      warningToast('Something went wrong. Please try again later')
                                                      setIsPlanClicked(false)
                                                      setDowngradingPlanId(null)
                                                      // Optionally, show a user-friendly message or perform other actions
                                                    }
                                                  })
                                                  .catch((error) => {
                                                    // Handle any errors that occur during dispatch or response handling
                                                    console.error(
                                                      'Error during subscription update:',
                                                      error
                                                    )
                                                      if(process.env['REACT_APP_ENV']==='staging'){
                                                        warningToast('An error occurred ' + error?.data?.massage )
                                                      }else{
                                                        warningToast('An error occurred. Please try again later')
                                                      }
                                                      setIsPlanClicked(false)
                                                      setDowngradingPlanId(null)
                                                    // Optionally, show a user-friendly message or perform other actions
                                                  })
                                              }
                                            }
                                          }
                                        }}
                                      >
                                        { downgradingPlanId===plan.id && isPlanClicked ? <div className='min-w-100px' ><Spinner animation='border' size='sm' /></div> : 'Downgrade'}
                                      </button>
                                    ) 
                                  }
                                </> 
                                : null 
                                }

                                    { fetchedUserAccountData?.source=='WOOCOMMERCE' && userSubscribedPlan?.current_billing_plan == plan.id && userSubscribedPlan?.cancelled_at!==null && plan?.billing_frequency!=='FREE_FOREVER'  ?
                                        <>
                                        <button
                                            className='btn btn-sm btn-danger shadow m-2 fw-bold fs-7 pt-1 w-150px'
                                            style={{paddingBottom:'4px'}}
                                            disabled
                                          >
                                            Cancelled
                                          </button>
                                          <p className='fs-8 fw-bold' >Valid Till {userSubscribedPlan?.valid_till ? moment.utc(userSubscribedPlan.valid_till).format('DD-MMM-YY hh:mm A') : '' }</p>
                                        </>
                                        : null  
                                    }

                                {userSubscribedPlan?.current_billing_plan === plan?.id && userSubscribedPlan?.isActive && userSubscribedPlan.cancelled_at===null
                                 ? plan.trialPeriodDays > 0 && (
                                    <>
                                      <button
                                        className='btn btn-sm btn-danger shadow m-2 fw-bold fs-7 pt-1'
                                        disabled={isPlanClicked}
                                        style={{paddingBottom:'4px'}}
                                        onClick={() => {
                                          if( ['WIX'].includes(fetchedUserAccountData?.source!)){
                                            setIsWixCancelling(true)
                                          }else{
                                            setIsCancelSubscriptionConfirm(true)
                                          }
                                        }}
                                      >
                                        Cancel Subscription
                                      </button></>
                                    
                                    )
                                  : plan.trialPeriodDays > 0 &&                                    
                                    (userSubscribedPlan?.billing_plan.billing_frequency !== "FREE_FOREVER" ) ||
                                    (plan.billing_frequency !== "FREE_FOREVER" && userSubscribedPlan?.activated_on == null) && (
                                      <button
                                        className='btn btn-sm btn-warning shadow m-2 fw-bold fs-7 py-1'
                                        style={{backgroundColor: 'orange'}}
                                        disabled={isPlanClicked}
                                        onClick={() => {
                                          setIsPlanClicked(true)
                                          setTrialingPlanId(plan.id)
                                          dispatch(
                                            requestSubscriptionAsync({
                                              plan_id: plan.id,
                                              platform_id: platformId??null,
                                              take_free_days_trial: plan.trialPeriodDays,
                                            })
                                          )
                                            .unwrap()
                                            .then((response) => {
                                              // Check if the response is successful
                                              if (response.status_code === 201 && response.data) {
                                                // Automatically open the link in a new tab
                                                window.open(response.data, '_self')
                                                setTimeout(() => {
                                                  setIsPlanClicked(false)
                                                  setTrialingPlanId(null)
                                                }, 4000);
                                              } else {
                                                // Handle error or different status codes
                                                console.error('Error response:', response)
                                                warningToast('Something went wrong. Please try again later')
                                                setIsPlanClicked(false)
                                                setTrialingPlanId(null)
                                                // Optionally, show a user-friendly message or perform other actions
                                              }
                                            })
                                            .catch((error) => {
                                              // Handle any errors that occur during dispatch or response handling
                                              console.error(
                                                'Error during subscription request:',
                                                error
                                              )
                                              if(process.env['REACT_APP_ENV']==='staging'){
                                                warningToast('An error occurred ' + error?.data?.massage )
                                              }else{
                                                warningToast('An error occurred. Please try again later')
                                              }
                                              setIsPlanClicked(false)
                                              setTrialingPlanId(null)
                                              // Optionally, show a user-friendly message or perform other actions
                                            })
                                        }}
                                      >
                                        { trialingPlanId===plan.id && isPlanClicked ? <div className='min-w-100px' ><Spinner animation='border' size='sm' /></div> : `Start ${plan?.trialPeriodDays}-Day Free Trial`}
                                      </button>
                                    )}
                                    
                                    { ((fetchedUserAccountData?.source=='WOOCOMMERCE' && userSubscribedPlan==undefined && plan.billing_frequency !== "FREE_FOREVER") || ( fetchedUserAccountData?.source=='WOOCOMMERCE' && plan.billing_frequency !== "FREE_FOREVER" && userSubscribedPlan?.isActive===false ))  &&
                                      <button
                                        className='btn btn-sm btn-warning shadow m-2 fw-bold fs-7 py-1'
                                        style={{backgroundColor: 'orange'}}
                                        disabled={isPlanClicked}
                                        onClick={() => {
                                          setIsPlanClicked(true)
                                          setTrialingPlanId(plan.id)
                                          dispatch(
                                            requestSubscriptionAsync({
                                              plan_id: plan.id,
                                              platform_id: platformId??null,
                                              take_free_days_trial: plan.trialPeriodDays,
                                            })
                                          )
                                            .unwrap()
                                            .then((response) => {
                                              // Check if the response is successful
                                              if (response.status_code === 201 && response.data) {
                                                // Automatically open the link in a new tab
                                                window.open(response.data, '_self')
                                                setTimeout(() => {
                                                  setIsPlanClicked(false)
                                                  setTrialingPlanId(null)
                                                }, 4000);
                                              } else {
                                                // Handle error or different status codes
                                                console.error('Error response:', response)
                                                warningToast('Something went wrong. Please try again later')
                                                setIsPlanClicked(false)
                                                setTrialingPlanId(null)
                                                // Optionally, show a user-friendly message or perform other actions
                                              }
                                            })
                                            .catch((error) => {
                                              // Handle any errors that occur during dispatch or response handling
                                              console.error(
                                                'Error during subscription request:',
                                                error
                                              )
                                              if(process.env['REACT_APP_ENV']==='staging'){
                                                warningToast('An error occurred ' + error?.data?.massage )
                                              }else{
                                                warningToast('An error occurred. Please try again later')
                                              }
                                              setIsPlanClicked(false)
                                              setTrialingPlanId(null)
                                              // Optionally, show a user-friendly message or perform other actions
                                            })
                                        }}
                                      >
                                        { trialingPlanId===plan.id && isPlanClicked ? <div className='min-w-100px' ><Spinner animation='border' size='sm' /></div> :  `Start ${plan?.trialPeriodDays}-Day Free Trial`}
                                      </button>
                                    }
                              </div>
                            </div>
                          </div>
                        </div>
                      )): (
                        <div className='card shadow' >
                          <div className="card-body">
                            <h3>No Plans Found</h3>
                          </div>
                        </div>
                      ) }
                  </div>
                </div>
              </div>
              <Link className='btn btn-sm btn-primary align-self-center fs-5  mb-6 mt-2 fw-bold'  to={'/listing-channels'} >Connect a new Channel or Change settings</Link>
            </div>
         
      </div>
      <CommonAlertModal
        title={['WIX'].includes(fetchedUserAccountData?.source!) ? 'You have to cancel your current plan from Wix admin portal.' : `Are you sure to cancel your subscription? It will remain active until your current plan expires. After that your plan will be downgraded to a Free plan.`}
        subtitle=''
        isTermsVisible={false}
        successDisabled={isCanceling}
        successBtnTitle={isCanceling? 'Updating...':'Yes Confirm'}
        isVisible={isCancelSubscriptionConfirm}
        onCancel={()=>{
          setIsCancelSubscriptionConfirm(false)
        }}
        onSuccess={()=>{
          if(!['WIX'].includes(fetchedUserAccountData?.source!)){
            setIsCanceling(true)
            dispatch(
              cancelSubscriptionAsync({
                subscription_id: userSubscribedPlan?.subscription_id,
                platform_id: platformId ?? null,
              })
            ).then((response) => {
              if (
                response?.payload?.status_code === 201 &&
                response?.payload?.data
              ) {
                successToast(response?.payload?.message)
                setIsCanceling(false)
                setIsCancelSubscriptionConfirm(false)
                dispatch(getUserPlanAsync()).then((response) => {
                  
                  if(response.payload?.data?.billing_plan){
                    setUserSubscriptionPlan({...response.payload?.data, user_count:response.payload?.user_count})
                  }
                  if(response.payload?.status===204){
                    //will not get user billing plan
                    setUserSubscriptionPlan(undefined)
                    // warningToast(response.payload?.data?.error)
                  }
                  if(response.payload?.status===500){
                    warningToast(response.payload?.data?.error)
                  }
                })
              } else {
                errorToast(response?.payload?.message)
                setIsCanceling(false)
                dispatch(getUserPlanAsync()).then((response) => {
                 
                  if(response.payload?.data?.billing_plan){
                    setUserSubscriptionPlan({...response.payload?.data, user_count:response.payload?.user_count})
                  }
                  if(response.payload?.status===204){
                    //will not get user billing plan
                    setUserSubscriptionPlan(undefined)
                    // warningToast(response.payload?.data?.error)
                  }
                  if(response.payload?.status===500){
                    warningToast(response.payload?.data?.error)
                  }
                })
              }
            })
          }else{
            setIsCancelSubscriptionConfirm(false)
          }
        }}
      />

      {/* downgrade to free plan confirmation popup */}
      <CommonAlertModal
        title={ userSubscribedPlan==undefined ? `Are you sure you want to proceed with the free plan?` : `Are you sure you want to downgrade to the Free plan?`}
        subtitle=''
        isTermsVisible={false}
        successDisabled={isCanceling}
        successBtnTitle={isCanceling? 'Updating...':'Yes Confirm'}
        isVisible={confirmDowngradeToFree}
        onCancel={()=>{
          setConfirmDowngradeToFree(false)
          setSelectedPlanId(null)
        }}
        onSuccess={()=>{
          setIsCanceling(true)
          dispatch(requestFreePlanForWoocom({plan_name: selectedPlanId }))
          .unwrap()
          .then((response) => {
            if (response.status_code === 200) {
              // Automatically open the link in a new tab
              successToast(response?.message)
              setIsCanceling(false)
              setIsLoading(true)
              Promise.all([
                dispatch(getAllPlansAsync()).then((response) => {
                  if (Array.isArray(response.payload)) {
                    setListOfPlans(response.payload)
                  } else {
                    console.error('Expected an array for listOfPlans:', response.payload)
                  }
                }),
                dispatch(getUserPlanAsync()).then((response) => {
                  console.log('list of  userplan', response)
                  if(response.payload?.data?.billing_plan){
                    setUserSubscriptionPlan({...response.payload?.data, user_count:response.payload?.user_count})
                  }
                  if(response.payload?.status===204){
                    //will not get user billing plan
                    setUserSubscriptionPlan(undefined)
                    // warningToast(response.payload?.data?.error)
                  }
                  if(response.payload?.status===500){
                    warningToast(response.payload?.data?.error)
                  }
                })
              ])
              .finally(()=>{
                setIsLoading(false)
              })
              
            } else {
              // Handle error or different status codes
              console.log('Error response:', response)
              setIsLoading(false)
              setIsCanceling(false)
              // Optionally, show a user-friendly message or perform other actions
            }
            setConfirmDowngradeToFree(false)
          })
          .catch((error) => {
            // Handle any errors that occur during dispatch or response handling
            setConfirmDowngradeToFree(false)
            setIsCanceling(false)
            setIsPlanClicked(false)
            console.error(
              'Error during subscription request:',
              error
            )
          })
           
        }}
      />

      <CommonAlertModal
        key={'WIX_USER_ALERT'}
        title={isWixDowngrading ? 'You need to cancel your current plan first through the Wix admin portal, and then you can choose your desired plan. Wix does not allow customers to manage plans through third-party sources.' :  isWixCancelling ? 'Wix does not permit customers to manage their plans through third-party platforms. To cancel your plan, please use the Wix Admin Portal.' : '' }
        subtitle=''
        isTermsVisible={false}
        successBtnTitle={'Ok'}
        showCloseButton={false}
        isVisible={isWixDowngrading||isWixCancelling}
        onCancel={()=>{}}
        onSuccess={()=>{
           setIsWixDowngrading(false)
           setIsWixCancelling(false)
        }}
      />
    </div>
  )
}

export default Pricing
